/* You can add global styles to this file, and also import other style files */
@import 'app/layout/style';
@import 'styles/themes';
@import 'styles/custom';
@import 'styles/grid';
@import 'styles/helpers';
@import 'styles/plugins';

.oha-blue-bg {
  background-color: #005595;
}

.oha-orange-bg {
  background-color: #EC891D;
}

.large-icon {
  transform: scale(3);
}

.medium-icon {
  transform: scale(2);
}

.icon-button {
  cursor: pointer;
}

.file-input {
  display: none;
}

.print-outer {
  overflow: hidden;
  position: relative;
}

.print-inner{
  position: absolute;
  height: auto;
  width: auto;
  left: -2000px;
  top: 0px;
}

.facilitator-button {
  border-radius: 27px;
  background: linear-gradient(145deg, #844dc6, #9d5beb);
  box-shadow:  5px 5px 10px #5b3588,
               -5px -5px 10px #cb75ff;
}

.testing-lab-button {
  border-radius: 27px;
  background: linear-gradient(145deg, #d47b1a, #fd931f);
  box-shadow:  5px 5px 10px #c97419,
               -5px -5px 10px #ff9e21;
}

.manufacturer-button {
  border-radius: 27px;
  background: linear-gradient(145deg, #e6ab29, #ffcb31);
  box-shadow:  5px 5px 10px #9e761d,
               -5px -5px 10px #ffff3f;
}

.service-center-button {
  border-radius: 27px;
  background: linear-gradient(145deg, #65cb6f, #78f184);
  box-shadow:  5px 5px 10px #458c4c,
               -5px -5px 10px #9bffaa;
}

.worker-permit-button {
  border-radius: 27px;
  background: linear-gradient(145deg, #d25f56, #f97066);
  box-shadow:  5px 5px 10px #90413b,
               -5px -5px 10px #ff9183;
}

.training-program-button {
  border-radius: 27px;
  background: linear-gradient(145deg, #5880dc, #6998ff);
  box-shadow:  5px 5px 10px #3d5897,
               -5px -5px 10px #87c4ff;
}

.neu-card {
  border-radius: 16px;
  background: #f0f0f5;
  box-shadow:  10px 10px 20px #ccccd0,
               -10px -10px 20px #ffffff;
}

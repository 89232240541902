// ==================================================
// GRID
// ==================================================

@use "sass:math";

.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: math.div($grid-gutter, -2);
  margin-right: math.div($grid-gutter, -2);
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;

  > .col,
  > [class*='col-'] {
    padding-right: 0;
    padding-left: 0;
  }
}

@include make-grid-columns();

// xsmall: 0,
// small:  600px
// medium: 960px
// large:  1280px
// xlarge: 1920px

@each $breakpoint, $infix in $breakpoint-infixs {
  @if ($breakpoint== 'xsmall') {
    @include loop-grid-columns($grid-columns, $infix);
  }

  @else {
    @include bp-gt($breakpoint) {
      @include loop-grid-columns($grid-columns, $infix);
    }
  }
}
